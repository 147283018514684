@import url("https://fonts.googleapis.com/css2?family=Righteous&display=swap");
@import url("https://fonts.googleapis.com/css?family=Pirata+One|Rubik:900");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400&display=swap");

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #0c1122;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #18402a;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #2d6848;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Righteous", cursive;
}

body {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(to bottom, #062726, #102b3f);
  background-size: contain;
  overflow-x: hidden;
  text-shadow: 0px 2px 3px #523c66;
  position: relative;
}

p {
  color: #06d6a0;
  text-shadow: 1px 2px 2px #062726;
}

h3 {
  color: #062726;
  text-shadow: 1px 2px 2px #06d6a0;
}

input,
select {
  border-radius: 15px;
  padding: 0 12px;
}

nav {
  width: 100%;
  min-height: 84px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: all 0.25s ease;
}

nav h1 {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 600px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 2em;
  transition: all 0.25s ease;
}

.particles-js {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
}

.particles-js-canvas-el {
  position: fixed;
  left: 0;
  top: 0;
  z-index: -1;
}

.home {
  padding: 0 16px;
}

.titleGem1 {
  width: 50px;
  z-index: 1000;
  transform: rotate(0deg);
  margin-right: 8px;
}
.titleGem2 {
  width: 50px;
  transform: rotate(0deg);
  z-index: 1;
  margin-left: 8px;
}

.links {
  width: 600px;
  height: 75px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.links i {
  margin-right: 6px;
}

a,
button {
  min-width: 100px;
  min-height: 32px;
  border: none;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  padding: 0 16px;
  transition: all 0.125s ease;
  text-decoration: none;
  color: white;
  background: #6247aa;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

a:hover,
button:hover {
  background: #a06cd5;
  transform: translateY(-3px);
}
a:active,
button:active {
  background: #a06cd5;
  transform: translateY(-1px);
}

#active {
  background: #a06cd5;
}

a i,
button i {
  color: white;
}

.welcome {
  width: 100%;
  max-width: 768px;
  height: auto;
  margin: 8px auto;
  padding: 16px;
  border-radius: 15px;
  background: rgba(226, 207, 234, 0.5);
  position: relative;
}

@media (max-width: 600px) {
  .welcome {
    max-width: 375px;
  }
}

.welcome:after {
  position: absolute;
  top: -4px;
  bottom: -4px;
  left: -4px;
  right: -4px;
  background: linear-gradient(to bottom, #6247aa, #ef476f);
  content: "";
  z-index: -1;
  border-radius: 16px;
}

.welcome h3 {
  width: 100%;
  border-bottom: 2px solid white;
}
.welcome p {
  width: 100%;
  padding: 32px;
}

.welcome span {
  color: #ffffff;
}

.biglink {
  width: calc(100% - 128px);
  max-width: 800px;
  min-height: 182px;
  height: auto;
  padding: 16px;
  margin: 0px auto;
  border-radius: 15px;
  background: rgba(226, 207, 234, 0.5);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 64px;
  position: relative;
}

.biglink:first-of-type {
  margin-top: 32px;
}

.biglink:after {
  position: absolute;
  top: -4px;
  bottom: -4px;
  left: -4px;
  right: -4px;
  background: linear-gradient(to bottom, #6247aa, rgba(239, 71, 110, 0.74));
  content: "";
  z-index: -1;
  border-radius: 16px;
}

.biglink img {
  width: 150px;
  height: auto;
  image-rendering: auto;
}

.biglink h2 {
  width: 100%;
  border-bottom: 4px solid white;
}
.biglink div {
  width: calc(100% - 166px);
  display: flex;
  flex-direction: column;
}
.biglink div p {
  margin-bottom: 8px;
}
.biglink > a {
  background: transparent;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  box-shadow: none;
}

.biglink > a:hover {
  background: transparent;
  transform: translateY(0px);
  box-shadow: none;
}

.biglink > a:active {
  background: transparent;
  transform: translateY(0px);
  box-shadow: none;
}

.biglink span {
  color: #ffffff;
}

.searchContainer {
  width: 100%;
  max-width: 800px;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 0px auto;
  margin-bottom: 32px;
  padding: 4px 32px;
}

.searchContainer * {
  padding: 4px 8px;
}

.searchContainer span {
  display: inline-block;
  width: 150px;
  color: white;
}

.searchContainer input,
select,
option {
  color: black;
}

.searchContainer select,
input {
  width: 100%;
}

.searchContainer div {
  display: flex;
  width: 100%;
}

.items-container {
  width: 100%;
  max-width: 800px;
  min-height: 500px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.item {
  background: #fcfcfc80;
  border-radius: 16px;
  image-rendering: pixelated;
  position: relative;
  width: 200px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 16px 32px;
  transition: all 0.25s ease;
  position: relative;
}

.item:after {
  position: absolute;
  top: -4px;
  bottom: -4px;
  left: -4px;
  right: -4px;
  background: linear-gradient(to bottom, #6247aa, #ef476f);
  content: "";
  z-index: -1;
  border-radius: 16px;
}

/* .item * {
  transition: all 0.25s ease;
} */

.item h3 {
  margin: 4px 0;
  text-align: center;
  height: 40px;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #26547c;
  text-shadow: 1px 2px 2px #06d6a0;
}
.item button {
  position: absolute;
  bottom: -30px;
  left: 50%;
  transform: translate(-50%, -50%);
}
.item button:hover {
  transform: translate(-50%, calc(-50% - 4px));
}

.item-image {
  image-rendering: pixelated;
  width: auto;
  height: 60px;
  margin: 4px 0;
}

.price {
  width: 100%;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
  text-shadow: 0px 1px 2px #523c66;
}

.price img {
  width: 16px;
  height: 16px;
  margin: 0 8px;
  image-rendering: auto;
}

.rarity {
  width: 30px;
  height: 30px;
  image-rendering: pixelated;
  text-shadow: 0px 1px 2px #523c66;
  transform: rotate(90deg);
}

.loading {
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease;
}
.loading div {
  width: 250px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
}

.loader {
  display: flex;
  justify-content: flex-end;
}

.loader span {
  width: 16px;
  height: 16px;
  background: linear-gradient(to bottom, #6247aa, #ef476f);
  margin: 0 2px;
  border-radius: 8px;
  animation: up-down 0.5s ease-in-out alternate infinite;
}

.loader span:nth-child(1) {
  animation-delay: 0;
}

.loader span:nth-child(2) {
  animation-delay: 250ms;
}
.loader span:nth-child(3) {
  animation-delay: 500ms;
}

#loadmore {
  margin: 100px 200px;
  max-height: 32px;
}

.legend {
  width: 250px;
  height: 250px;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: transparent;
  image-rendering: pixelated;
  position: fixed;
  left: -400px;
  bottom: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  padding: 8px 24px;
  transition: all 0.25s ease;
}

.legend:hover {
  left: 8px;
}

#legendLabel {
  position: fixed;
  left: 25px;
  bottom: 12px;
  transform: translate(-50%, -50%);
  transition: all 0.25s ease;
  width: 30px;
  height: 30px;
  background: rgba(102, 102, 102, 0.6);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
#legendLabel:hover .legend {
  left: 8px;
}

#legendLabel:hover {
  opacity: 0;
}
.legend:hover #legendLabel {
  opacity: 0;
}

.legend div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.legend p {
  text-shadow: 0px 1px 2px #523c66;
  font-size: 20px;
}
.legend img {
  transform: rotate(90deg);
  width: 30px;
  height: 39px;
}

.owned {
  width: 100%;
  height: auto;
  font-size: 18px;
  text-align: center;
  display: flex;
  flex-direction: column;
  transform: translateY(-4px);
}

.owned span {
  font-size: 12px;
}

.modal-container {
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease;
  z-index: 11111;
}

.modal {
  position: relative;
  width: 350px;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: slide-down 0.25s ease;
  background: #18181b;
  border-radius: 16px;
  margin-top: 132px;
}

@media (max-width: 600px) {
  .modal {
    margin-top: 210px;
  }
}

.modal:after {
  position: absolute;
  top: -4px;
  bottom: -4px;
  left: -4px;
  right: -4px;
  background: linear-gradient(to bottom, #6247aa, #ef476f);
  content: "";
  z-index: -1;
  border-radius: 16px;
}

.chatWrapper {
  border-radius: 16px;
  padding: 16px 0 12px;
}

.playerWrapper {
  width: 358px;
  display: flex;
  justify-content: center;
  padding: 4px;
  margin: 24px auto;
  top: 20px;
  z-index: 1111;
}

.player {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
}

.player iframe {
  border-radius: 8px;
}

.playerWrapper:after {
  position: absolute;
  top: -4px;
  bottom: -4px;
  left: -4px;
  right: -4px;
  background: linear-gradient(to bottom, #6247aa, #ef476f);
  content: "";
  z-index: -1;
  border-radius: 16px;
}

.modal p {
  width: 100%;
  height: 116px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-wrap: wrap;
  color: #ffffff;
  background: #ffd166;
  position: absolute;
  bottom: 396px;
  padding: 16px 8px;
  letter-spacing: 1px;
  margin-top: 22px;
}
.modal p span {
  color: #ef476f;
  margin: 0 4px;
}

.modal #command {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 8px;
  margin: 8px;
  color: rgb(0, 189, 0);
  background: #333333;
  border: 2px solid black;
  margin: 8px;
  width: 70%;
  height: 30%;
  resize: none;
  font-size: 14px;
  overflow: hidden;
  text-align: center;
  font-family: "Lucida Console", "Courier New", monospace;
  line-height: 22px;
}

#close {
  min-width: 32px;
  width: 32px;
  height: 30px;
  image-rendering: pixelated;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  top: -15px;
  right: -18px;
  padding: 0;
}

#code-copied {
  position: absolute;
  top: 75%;
  animation: slide-down-code 5s;
  opacity: 0;
}

.skin {
  width: 50%;
  min-width: 300px;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 5px solid black;
  border-radius: 15px;
  background: white;
  margin: 8px 16px;
}

.skinInfo {
  width: 230px;
  height: 230px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  image-rendering: pixelated;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 0 auto;
  padding: 4px 12px;
  text-align: center;
  position: relative;
}
.skinInfo h4 {
  position: relative;
  width: auto;
  background: linear-gradient(to right, #24243e, #141e30, #0f0c29);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: Uppercase;
  font-family: "Rubik", sans-serif;
  border-bottom: 2px solid #24243e;
  padding: 4px;
}

.skinInfo h4:before,
.skinInfo h4:after {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
}

.skinInfo h4:before {
  z-index: -1;
  text-shadow: -0.001em -0.001em 1px rgba(255, 255, 255, 0.15);
}

.skinInfo h4:after {
  z-index: -2;
  text-shadow: 10px 10px 10px rgba(0, 0, 0, 0.5),
    20px 20px 20px rgba(0, 0, 0, 0.4), 30px 30px 30px rgba(0, 0, 0, 0.1);
  mix-blend-mode: multiply;
}

.skinInfo .plaque {
  background: url(../src/images/plaquebackground.png);
  image-rendering: auto;
  background-size: cover;
  background-repeat: no-repeat;
  width: 150px;
  height: 75px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  text-transform: Uppercase;
  font-family: "Rubik", sans-serif;
}
.skinInfo h6 {
  width: 80%;
  word-wrap: break-word;
  text-align: justify;
  color: black;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.15);
}
.skinInfo a {
  transform: scale(0.9);
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes slide-down {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  70% {
    transform: translateY(10%);
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-down-code {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  7% {
    transform: translateY(10%);
  }
  10% {
    transform: translateY(0);
    opacity: 1;
  }
  90% {
    transform: translateY(0);
    opacity: 1;
  }
  95% {
    opacity: 0;
  }
  100% {
    transform: translateY(-100%);
    opacity: 0;
  }
}

@keyframes hover {
  0% {
    top: -35px;
  }
  50% {
    top: -45px;
  }
  100% {
    top: -35px;
  }
}

@keyframes up-down {
  from {
    height: 16px;
  }
  to {
    height: 96px;
  }
}

@media (max-width: 600px) {
  nav h1 {
    width: 300px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
  }

  a,
  button {
    min-width: 30px;
    min-height: 32px;
    border: none;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    padding: 0 16px;
    transition: all 0.125s ease;
    text-decoration: none;
    color: white;
    background: #6247aa;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.5);
    cursor: pointer;
  }

  a:hover,
  button:hover {
    background: #a06cd5;
    transform: translateY(-3px);
  }
  a:active,
  button:active {
    background: #a06cd5;
    transform: translateY(-1px);
  }

  .home {
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .links {
    width: 320px;
  }
  .links i {
    margin: 0;
    color: white;
  }

  .searchContainer {
    height: auto;
    width: 100%;
  }
  .searchContainer div {
    flex-direction: column;
  }
  .searchContainer div span {
    display: block;
    width: 100%;
  }

  .item {
    margin: 16px 2%;
    width: 300px;
    height: 300px;
  }
  .item h3 {
    color: #26547c;
    text-shadow: 0px 2px 3px #523c66;
    margin: 8px 0;
    font-size: 24px;
    font-family: "Montserrat", sans-serif;
  }
  .item p {
    font-size: 22px;
  }
  .item-image {
    image-rendering: pixelated;
    width: auto;
    height: 80px;
    margin: 12px 0;
  }
  .biglink {
    flex-direction: column;
    width: 100%;
  }

  .biglink > a {
    width: 100%;
    height: 100px;
  }
  .biglink > a img {
    width: auto;
    height: 100px;
  }
  .biglink div {
    width: 100%;
  }
}

.new-item {
  position: absolute;
  right: 0;
  top: 0;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 10px;
  text-align: center;
  z-index: 111;
}

.new-item:before {
  position: absolute;
  right: 0;
  top: 0;
  content: "";
  width: 40px;
  height: 40px;
  background: #06d6a0;
  transform: rotate(45deg);
  z-index: -1;
  animation: new-rotate-offset 5s linear infinite;
}

.new-item:after {
  position: absolute;
  right: 0;
  top: 0;
  content: "";
  width: 40px;
  height: 40px;
  background: #06d6a0;
  transform: rotate(0);
  z-index: -1;
  animation: new-rotate 5s linear infinite;
}

@keyframes new-rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes new-rotate-offset {
  from {
    transform: rotate(45deg);
  }
  to {
    transform: rotate(405deg);
  }
}
