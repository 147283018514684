.formContainer {
  width: calc(100% - 128px);
  max-width: 800px;
  min-height: 182px;
  height: auto;
  padding: 16px;
  margin: 0px auto;
  border: 5px solid black;
  border-radius: 15px;
  background: #ead4aa;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 64px;
}
form {
  display: flex;
  flex-wrap: wrap;
}

.merchHeader {
  display: flex;
  width: 100%;
  padding: 32px 16px;
  text-align: center;
}

.merchHeader i {
  display: block;
  width: 50%;
  margin: 16px auto;
  background: white;
  padding: 4px 32px 4px 16px;
  border-radius: 10px;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.8);
  position: relative;
}

.merchHeader i::after {
  content: "";
  border: 2px solid green;
  border-left-color: transparent;
  border-top-color: transparent;
  width: 6px;
  height: 16px;
  position: absolute;
  right: 6px;
  top: 40%;
  transform: translate(-50%, -50%) rotate(45deg);
}

.merchDescription {
  width: 80%;
  margin: 0 auto 16px;
  padding: 16px;
  border-bottom: 2px solid white;
  border-top: 2px solid white;
}

.hoodie {
  width: 50%;
}

.input {
  width: 100%;
}

.input input {
  width: calc(100% - 32px);
  height: 30px;
  margin: 6px 16px;
  padding: 2px 8px;
}
.input textarea {
  width: calc(100% - 32px);
  height: 90px;
  margin: 6px 16px;
  padding: 8px;
  resize: none;
}

.wide label {
  position: absolute;
  top: 10px;
  left: 16px;
}
.wide input {
  margin-top: 32px;
}
.wide textarea {
  margin-top: 32px;
}

.side {
  width: 50%;
}

.input {
  position: relative;
}

.quantity {
  display: flex;
  justify-content: space-between;
  padding: 0 16px;
  position: relative;
}

.quantity label {
  width: 25%;
  height: 30px;
  position: relative;
  margin-top: 32px;
}

.quantity label:first-child {
  width: 70%;
}
.quantity label select {
  width: 100%;
  height: 30px;
  padding: 0 8px;
}

.quantity label::after {
  content: attr(datalabel);
  position: absolute;
  left: 0;
  top: -24px;
  width: 100%;
  height: 30px;
}

.total {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 16px 32px;
  text-align: right;
}

.total div {
  margin-left: 48px;
  width: 40%;
  display: flex;
  justify-content: space-evenly;
}

.paypal {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 0 32px;
}
