.nowhiteborder p {
  border-bottom: none;
  text-align: center;
}

.soundAlerts {
  width: 100%;
  max-width: 1000px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 128px;
}

.soundFilter {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
  max-width: 800px;
  min-height: 90px;
  margin: 8px auto;
  border-radius: 15px;
  position: relative;
  background: rgba(252, 252, 252, 0.65);
  padding: 12px;
  border-radius: 13px;
}

.soundFilter div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 4px;
}
.soundFilter div div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  min-width: 80%;
  margin: 0;
}

.soundFilter div h3 {
  min-width: 100px;
  text-align: left;
}

.soundFilter div button {
  margin: 4px;
}

.soundFilter input {
  width: calc(100% - 120px);
  height: 30px;
  border: none;
}

@media (max-width: 600px) {
  .soundFilter div h3 {
    min-width: 30px;
    width: 20%;
    text-align: left;
  }
  .soundFilter input {
    width: 80%;
    height: 30px;
    border: none;
  }
}

.sound {
  width: 250px;
  background: rgba(252, 252, 252, 0.65);
  padding: 12px;
  border-radius: 13px;
  margin: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.sound:after {
  position: absolute;
  top: -4px;
  bottom: -4px;
  left: -4px;
  right: -4px;
  background: linear-gradient(to bottom, #6247aa, #ef476f);
  content: "";
  z-index: -1;
  border-radius: 16px;
}

.soundInfo {
  width: 100%;
  height: auto;
  min-height: 50px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.sound .link {
  color: white;
  background: #6247aa;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.5);
  font-weight: 400;
  letter-spacing: 1px;
  text-shadow: none;
  margin-top: 8px;
  width: 100%;
  text-align: center;
  border-radius: 10px;
  transition: all 0.25s ease;
}

.sound .link:hover {
  background: #a06cd5;
  transform: translateY(-3px);
}
.sound .link:active {
  background: #a06cd5;
  transform: translateY(-1px);
}

.soundInfo p {
  margin: 0;
  font-family: Inter, Roobert, "Helvetica Neue", Helvetica, Arial;
  font-size: 12px;
  text-decoration: underline;
  text-shadow: 1px 1px black;
}

.soundInfo h3 {
  color: white;
  text-shadow: 1px 2px 2px #062726;
  font-family: Inter, Roobert, "Helvetica Neue", Helvetica, Arial;
  font-size: 16px;
  width: calc(100% - 50px);
}

.soundTags {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.tag {
  color: white;
  background: white;
  font-size: 14px;
  min-width: calc(50px + 8px);
  height: 20px;
  text-align: center;
  border-radius: 10px;
  padding: 0px 8px;
  text-shadow: none;
  margin-bottom: 4px;
  margin-right: 4px;
  text-shadow: 1px 1px black;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.5);
}
.new {
  background: #06d6a0;
}
.memes {
  background: #ef476f;
}
.anime {
  background: #ffd166;
}
.soundeffect {
  background: #6247aa;
}
.music {
  background: #26547c;
}
.movie {
  background: #79267c;
}
.videogame {
  background: #ef47c3;
}
.minecraft {
  background: #7c4e26;
}
.clear {
  height: 30px;
  background: white;
  color: black;
  animation: fade-in 0.5s ease;
}

.clear:hover {
  transform: none;
}

@keyframes fade-in {
  0% {
    height: 0;
    opacity: 0;
  }
  50% {
    height: 36px;
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    height: 36px;
    opacity: 1;
  }
  50% {
    height: 36px;
    opacity: 0;
  }
  100% {
    height: 0;
    opacity: 0;
  }
}

.sound button {
  width: 32px;
  height: 32px;
  min-width: 32px;
  min-height: 32px;
  border-radius: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  transition: all 0.125s ease;
}

.soundButton {
  color: white;
  background: #6247aa;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.5);
  margin-top: 40px;
}

.soundButton:hover {
  background: #a06cd5;
  transform: translateY(-6px);
}
.soundButton:active {
  background: #a06cd5;
  transform: translateY(-3px);
}

.sound button i {
  color: white;
}

.sa-modal .modal {
  background: #18181b;
  border-radius: 10px;
  padding: 0;
}

.sa-modal .modal #close {
  top: 16px;
  right: 16px;
  padding: 0;
  min-width: 32px;
}
.sa-modal .modal button {
  padding: 0;
}
.sa-modal .modal p {
  margin: 4px 4px 4px 16px;
  text-align: left;
  width: auto;
}

.sa-modal .channelReward {
  background: #18181b;
  margin-top: 16px;
}

.sa-modal .rewardDetails {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.sa-modal .rewardLogo {
  width: 24px;
  height: 24px;
  background: #00c7ac;
  border-radius: 5px;
}

.sa-modal .rewardTitle {
  font-family: Inter, Roobert, "Helvetica Neue", Helvetica, Arial;
  margin: 0;
  font-weight: 600;
}

.sa-modal .banana {
  width: 24px;
  height: 24px;
  image-rendering: auto;
}

.sa-modal .modal #command {
  background: #464649;
  border-radius: 6px;
  text-align: left;
  font-family: Inter, Roobert, "Helvetica Neue", Helvetica, Arial;
  color: white;
  font-weight: 400;
  width: 280px;
  height: 130px;
  border: none;
  margin: 8px 0;
  padding: 8px;
}
.sa-modal .smile {
  fill: #efeff1;
  position: absolute;
  right: 4px;
  bottom: 26px;
  width: 40px;
  height: 40px;
}
